
export default {
  ADD_ITEM(state, item) {
    state.Countries.unshift(item);
  },
  SET_Countries(state, Countries) {
    state.Countries = Countries;
  },
  UPDATE_Country(state, Country) {
    const CountryIndex = state.Countries.findIndex(
      p => p.ID == Country.ID
    );
    if (CountryIndex != -1) {
      Object.assign(state.Countries[CountryIndex], Country);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Countries.findIndex(p => p.ID == itemId);
    state.Countries.splice(ItemIndex, 1);
  }
};
