
export default {
  ADD_ITEM(state, item) {
    state.Cities.unshift(item);
  },
  SET_Cities(state, Cities) {
    state.Cities = Cities;
  },
  UPDATE_City(state, City) {
    const CityIndex = state.Cities.findIndex(
      p => p.ID == City.ID
    );
    if (CityIndex != -1) {
      Object.assign(state.Cities[CityIndex], City);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Cities.findIndex(p => p.ID == itemId);
    state.Cities.splice(ItemIndex, 1);
  }
};
