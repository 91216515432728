
import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios.post("api/Country/AddCity", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(response.data.data, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Country/GetAllCities")
        .then((response) => {
          commit('SET_Cities', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put("api/Country/UpdateCountry", item)
        .then((response) => {
          commit('UPDATE_City', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("api/Country/GetCity?ID="+ itemid)
        .then((response) => {
          commit('UPDATE_City', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  removeItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Country/DeleteCity?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
